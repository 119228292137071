import React, { Suspense } from "react";
import { graphql, HeadFC } from "gatsby";
import Layout from "../components/layout";
import { Trans } from "react-i18next";
import { HeaderSpacer } from "../components/header";
import Timetable from "../components/timetable";
import Form, {
  Input,
  Label,
  Select,
  Submit,
  Textarea,
} from "../components/forms";
import Heading from "../components/heading";
import Modal, { modalButtonClass } from "../components/modal";
import { useI18next } from "gatsby-plugin-react-i18next";
import SEO from "../seo";
import smartphone from "../images/smartphone.png";

const PdfReader = React.lazy(() => import("../components/pdf_reader"));

const ContactForm = () => {
  const { language } = useI18next();
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const privacyFile = `/panzero-privacy-policy.${language}.pdf`;

  return (
    <div className="">
      <Heading>Contact us</Heading>
      <Form
        emailSubject="Contact-us"
        formsparkId="1GWP0YSA"
        botpoisonKey="pk_a284c529-9518-4751-b12f-eb75deaa2633"
        className="grid mt-4 mb-32"
      >
        <Label htmlFor="firstNameAndLastName" required>
          Name and Last Name
        </Label>
        <Input
          type="text"
          id="firstNameAndLastName"
          name="name_and_last-name"
          placeholder="Type your name and last name"
          required
        />
        <Label htmlFor="email" required>
          Email
        </Label>
        <Input
          type="text"
          id="email"
          name="email"
          placeholder="Type your email"
          required
        />
        <Label htmlFor="phone" required>
          Phone Number
        </Label>
        <Input
          type="tel"
          id="phone"
          name="phone"
          placeholder="e.g. +31(0)6 01010101"
          required
        />
        <Label htmlFor="subject">Select a Subject</Label>
        <Select id="subject" name="subject" required>
          <option value="suggestions">
            <Trans>Suggestions</Trans>
          </option>
          <option value="feedback">
            <Trans>Feedback</Trans>
          </option>
          <option value="lost_and_found">
            <Trans>Lost & found</Trans>
          </option>
          <option value="reservation">
            <Trans>Reservation</Trans>
          </option>
          <option value="delivery">
            <Trans>Delivery</Trans>
          </option>
        </Select>
        <Label htmlFor="message">Message</Label>
        <Textarea id="message" name="message" placeholder="Type your message" />
        <div className="flex items-center">
          <input
            type="checkbox"
            className="m-2 text-xl"
            name="privacy_policy"
            id="privacy"
            required
          />
          {/* prettier-ignore */}
          <Label htmlFor="privacy" required>I have read and agreed to the <span className="bg-yellow-500 px-2" onClick={() => setOpenPrivacy(true)}>PRIVACY POLICY</span></Label>
          <Modal
            open={openPrivacy}
            onClose={() => setOpenPrivacy(false)}
            action={
              <a
                href={privacyFile}
                target="_blank"
                title="privacy policy download"
                className={modalButtonClass}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </a>
            }
          >
            <Suspense>
              <PdfReader pdf={privacyFile} />
            </Suspense>
          </Modal>
        </div>
        <Submit>Send</Submit>
      </Form>
    </div>
  );
};

const Contact = () => {
  return (
    <Layout className="snap-proximity scroll-pt-32 relative">
      <HeaderSpacer />
      <div className="bg-white/90 max-w-screen-lg lg:mx-auto">
        <img
          src="/contact/cover.jpg"
          className="w-full lg:fixed inset-0 z-[-1]"
        />
        <Heading>Contact us</Heading>
        <p className="p-4">
          {/* prettier-ignore */}
          <Trans>Send us your questions, comments or feedback so we can serve you better 😊</Trans>
        </p>
        <Heading>Open Hours</Heading>
        <div className="flex justify-center">
          <Timetable />
        </div>
        <Heading>Get In Touch</Heading>
        <ul className="mt-2">
          <li>
            <a
              href="tel:+310102378426"
              className="text-brown-500 bg-brown-500/0 active:bg-yellow-500/50 flex text-2xl items-center p-4 gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
              <span className="select-all">+31 (0)10 237 84 26</span>
            </a>
          </li>
          <li className="text-brown-500 text-2xl p-4 flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
              />
            </svg>
            <div className="flex flex-col items-start">
              <address className="select-all">
                Botersloot 50A, 3011 HH Rotterdam
              </address>
              <a
                href="https://goo.gl/maps/9Rwtpi58p7vrV6QW9"
                target="_blank"
                className="bg-yellow-300 text-sm px-2"
              >
                Google maps
              </a>
            </div>
          </li>
        </ul>
        <div className="relative w-full h-64 overflow-hidden">
          <span
            style={{
              background: `center / contain url(${smartphone}) no-repeat`,
            }}
            className="absolute w-60 h-60 -right-12 bottom-0 animate-phone"
          />
        </div>
        <ContactForm />
        <div className="mt-4">
          <Heading>Leave us a Review</Heading>
          <ul className="text-brown-500 flex flex-col">
            <li className="p-2 m-2">
              <a
                className="text-2xl"
                href="https://www.tripadvisor.com/UserReviewEdit-g188632-d10458598-a_referredFromLocationSearch.true-a_ReviewName.-a_type.-e-wpage1"
                target="_blank"
              >
                <img
                  className="w-auto h-12"
                  src="/promo/tripadvisor.png"
                  alt="Tripadvisor"
                />
              </a>
            </li>
            <li className="p-2 m-2">
              <a
                className="text-2xl"
                href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x47c4335b0ee53379:0x7b6e4b4a265fdf56!12e1?source=g.page.m.kd._&laa=lu-desktop-review-solicitation"
                target="_blank"
              >
                <img
                  className="w-auto h-12"
                  src="/promo/google.png"
                  alt="Google"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};
//https://g.page/PanzeroItalianStrEatFood?share

export default Contact;

export const Head: HeadFC = () => <SEO title="Contact us" />;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
